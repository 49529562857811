import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`According to Ulema, Ism (الاسم) is divided into two`}</p>
    <ul>
      <li parentName="ul">{`مُتَمَكِّن`}</li>
      <li parentName="ul">{`غير مُتَمَكِّن Or المبني`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "441px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.39877300613497%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABTklEQVQoz4WO226CQBCGef8XMTa9sAcBpVVrYgiiUDUochDQKgqaRsNpl92lS22aHkw7mYt/dr4vs0xxqdwATFZAe8l0Hxk7oi5OIzearuF4me1POQUIKTHmt0kIccNc97EdFl3V43taTZD7s5CO2joP/5Zp+a/Q3kHvgMfW/nkemOvECSBtewuOMfpHpjvaOUQIYoILCHKa3x/JV+yyjDEuzRwVHzChuRy/F0O3Z+AcECYQINr4xxW6QhhChDH5hJnMNIBlJnM9swzk2JE2i/UZDbljQ8vIFnapRVFqzIFtJrp+HE+gbWaGkVkmk4i9ldBQqxWXZ3edlnF3o1Qrm0dh1eRdrh73RSrDIEgkESiyw7Oj66tlg4OKnMoSE0uiJzRM9n7Tbpls3Xto+p2WxbHT21rQ7aTqkH4vP+zTgZQqg0WDo+T2qQ3UYTqU3wAuy/RRKP1zpgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Two Types of Isms",
          "title": "Two Types of Isms",
          "src": "/static/5431c17065e4d80ee32a90bc72a8b98a/efc6e/two-types-of-isms.png",
          "srcSet": ["/static/5431c17065e4d80ee32a90bc72a8b98a/222b7/two-types-of-isms.png 163w", "/static/5431c17065e4d80ee32a90bc72a8b98a/ff46a/two-types-of-isms.png 325w", "/static/5431c17065e4d80ee32a90bc72a8b98a/efc6e/two-types-of-isms.png 441w"],
          "sizes": "(max-width: 441px) 100vw, 441px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`مُتَمَكِّن`}</strong></h2>
    <p>{`It is divided into two`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`مُتَمَكِّن أَمكِن`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`It has all the features of a noun `}</li>
          <li parentName="ul">{`They are termed as الاسم المنصرف and accepts Damma, Fatha, Kasra.`}</li>
          <li parentName="ul">{`They take Tanween`}</li>
          <li parentName="ul">{`Example: كتابٌ, بنتٌ, رجل`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`مُتَمَكِّن غير أَمكِن`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`It has some features of Fa'il `}</li>
          <li parentName="ul">{`They are termed as Diptote Or Mamnu Min As Sarf (الممنوع من الصرف) and accepts only Damma and Fatha.`}</li>
          <li parentName="ul">{`They don't take Tanween`}</li>
          <li parentName="ul">{`Example: عمرُ`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "561px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.306748466257666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABf0lEQVQoz3WRiU7CQBCG+/5PYUxURBOjKEEIosYoeHEERaEgh0ALFErk6F793W65xUn+3dmZ2W+P0bBirusqeTYlAoMRw3AiMBxzf574MS+3WT837S9QeWj2Kd6aDO9NilAii7NEBq+6jUJLoGFRVePV/gucnzYHmjZFyeTIV4eI3uURT35I34YuY4a9DlyFakvQUpjNjHFQwhfFlMo1ZXDFsmZTa09e3FaKy4EL3199gZjFBbabJigF7XYh+pYS6/XgtE0wGeNWD7Qnc4QsDmJDG7Tj5x3DBAZ9tU8xJEujHuApBfMmgcLpiZrr0QjSR4eoxy5VzgMoIOeY5DKYyphxfQVTqhw+V76q63YkUA5ELqqRMF4C+8gdB5GRsPvdHbTiMZDHJIjRVkDBGJxsBtZtAulgAPpFCM8He+oCTuoB1DSg8fEYTkUH+apgVPr0VfRFKmU4ZR189LP4R/LdwFQvgteqoHKPW68p368b+U1xsfz8zeasNmVbTsw0z/4CbxT6QdLWIEMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Two Types of Mutanakkin",
          "title": "Two Types of Mutanakkin",
          "src": "/static/339b99e0fecdcbfaff4b61d3b9b3ec54/410f3/2-mutanakkin.png",
          "srcSet": ["/static/339b99e0fecdcbfaff4b61d3b9b3ec54/222b7/2-mutanakkin.png 163w", "/static/339b99e0fecdcbfaff4b61d3b9b3ec54/ff46a/2-mutanakkin.png 325w", "/static/339b99e0fecdcbfaff4b61d3b9b3ec54/410f3/2-mutanakkin.png 561w"],
          "sizes": "(max-width: 561px) 100vw, 561px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`غير مُتَمَكِّن`}</strong></h2>
    <p>{`They are called غير متمكن because they don't look like a noun. Instead, they look like الحرف. Example: الضمائر  `}</p>
    <p><strong parentName="p">{`PS:`}</strong>{` Why they look like harf require more detailed explanation which we out of scope of this tutorial`}</p>
    <br />
    <p>{`Moving deeper into the topic, Let's take a step back and define `}</p>
    <h3><strong parentName="h3" {...{
        "className": "arabic"
      }}>{`الصرف`}</strong></h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`الصرف`}</code>{` is equal to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`التنوين`}</code>{`, So  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`الممنوع من الصرف`}</code>{` is equivalent to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ممنوع من التنوين`}</code>{` which is equivalent to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ممنوع من الكسرة`}</code>{` i.e. ( يجر بالفتحة)`}</p>
    <br />
    <p>{`For example in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ذهبت إلى عمرَ`}</code>{`, here `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`عمرَ`}</code>{` is ممنوع من الصرف and has Fatha in Jar form.`}</p>
    <br />
    <p>{`But the burning question is`}</p>
    <h2>{`Why we have Diptote Or Mamnu Min As Sarf`}</h2>
    <p>{`ُThe answer to the existence Diptote Or Mamnu Min As Sarf can be broadly divided into two categories,  `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 1`}</strong>{` When we need only `}<strong parentName="p">{`one reason`}</strong>{` to explain why it is Diptote Or Mamnu Min As Sarf  `}</p>
    <br />
    <p>{`Those reasons can be as follows,`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Reason 1`}</strong>{` If we have Alif of Feminine in the end i.e. ألف التأنيث المقصورة أو الممدودة`}<br parentName="p"></br>{`
`}{`Examples for ألف التأنيث المقصورة,`}</p>
    <ul>
      <li parentName="ul">{`ذهبت إلى `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ليلى`}</strong>
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`ليلى`}</strong>{`
اسم مجرور وعلامة جره الفتحة نيابة عن الكسرة لأنه ممنوع من الصرف لوجود ألف التأنيث المقصورة`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`حبلى`}</li>
    </ul>
    <br />
    <p>{`Examples for ألف التأنيث الممدودة,`}</p>
    <ul>
      <li parentName="ul">{`ذهبت إلى فتاةٍ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`شقراءَ`}</strong>
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`شقراءَ`}</strong>{`
نعت مجرور وعلامة جره الفتحة نيابة عن الكسرة لأنه ممنوع من الصرف لوجود
ألف التأنيث الممدودة`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`شيماء, حسناء, أشياء`}</li>
    </ul>
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception for ألف التأنيث الممدودة`}</strong>{` If a word has three or four letters then it is مصروفة. For example ماءٌ, دواءٌ, سماء, هواء etc `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`Reason 2`}</strong>{` If we have Ism on form (وزن) of مَفاعِل OR مَفَاعِيل Which is also known as صيغة مُنتَهى الجُمُوع
For Example,`}</p>
    <ul>
      <li parentName="ul">{`ذهبت إلى `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`مساجدَ`}</strong>{` كثيرةٍ`}
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p" {...{
              "className": "arabic irab"
            }}>{`مساجد`}</strong>{`
اسم مجرور وعلامة جره الفتحة نيابة عن الكسرة لأنه ممنوع من الصرف صيغة منتهى الجمع`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`مصابيْح`}</li>
      <li parentName="ul">{`قناديْل`}</li>
    </ul>
    <br />
    <p>{`The Alif in مساجد OR مصابيْح is ألف التكسير.`}</p>
    <br />
    <p>{`As a rule to apply `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Reason 2`}</strong>{`, we should only have after Alif of التكسير `}</p>
    <ul>
      <li parentName="ul">{`Two letters e.g. in مساجد Or `}</li>
      <li parentName="ul">{`Three letters + Middle word of those 3 letters will be Sakin e.g. in مصابيْح `}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`جمع تكسير بعد ألف التكسير حرفان أو ثلاثة ويكون الحرف الأوسط مع الثلاثة سكان`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 2`}</strong>{` When we need `}<strong parentName="p">{`Two reasons`}</strong>{` to explain why it is Diptote Or Mamnu Min As Sarf `}</p>
    <br />
    <p>{`Those reasons can be grouped as follows,`}</p>
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th" {...{
              "className": "arabic heading1"
            }}>{`الوصفية`}</strong>{` (Name with meaning of Sifa)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th" {...{
              "className": "arabic heading1"
            }}>{`العلمية`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`إبراهيم - يوسف`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td" {...{
              "className": "exception"
            }}>{`العُجْمَة`}</strong>{` (Non Arab, Foreign Name)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(فاطمة (مؤنث لفظاً ومعنى) – زينب (مؤنث معنى فقط) –  طلحة (مؤنث لفظاً فقط) -  مصر (أسماء البلاد والمدن مؤنث`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td" {...{
              "className": "exception"
            }}>{`التأنيث`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`بعلبك - حضرموت`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td" {...{
              "className": "exception"
            }}>{`التركيب`}</strong>{`  (Joining two words)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`غضبان – شبعان - ريَّان`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`عثمان - رمضان`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td" {...{
              "className": "exception"
            }}>{`زيادة الألف والنون`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`مثنى – ثلاث – رباع - أُخر`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`عُمَر - قُزَح`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td" {...{
              "className": "exception"
            }}>{`العَدْل`}</strong>{` (Leaving one thing/option for another thing/option e.g. عُمَر was actually عامر )`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`أحسن - أفضل - أكرم  - أحمر`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`أحمد – يزيد - يشكر`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td" {...{
              "className": "exception"
            }}>{`وزن الفعل`}</strong></td>
        </tr>
      </tbody>
    </table>
    <br />
    <p>{`For example, word إبراهيم is Diptote Or Mamnu Min As Sarf because of two reasons,`}</p>
    <ol>
      <li parentName="ol">{`It is العلمية`}</li>
      <li parentName="ol">{`It is العُجْمَة`}</li>
    </ol>
    <br />
    <p><strong parentName="p">{`Side Note:`}</strong><br parentName="p"></br>{`
`}{`Names of prophet are Diptote Or Mamnu Min As Sarf Except,`}</p>
    <ul>
      <li parentName="ul">{`محمد`}</li>
      <li parentName="ul">{`هود`}</li>
      <li parentName="ul">{`لوط`}</li>
      <li parentName="ul">{`نوح`}</li>
      <li parentName="ul">{`شعيب`}</li>
      <li parentName="ul">{`صالح`}</li>
    </ul>
    <br />
    <p><strong parentName="p">{`Irab`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`جاء آدمُ`}</strong>{`  `}</p>
    <p>{`آدم
: فاعل مرفوع وعلامة رفعه الضمة`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`خلق الله ادمَ`}</strong>{`  `}</p>
    <p>{`آدم
: مفعول به منصوب وعلامة نصبه الفتحة`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`ذهبت إلى ادمَ`}</strong>{`  `}</p>
    <p>{`آدم
: اسم مجرور وعلامة جره الفتحة نيابة عن الكسرة لأنه ممنوع من الصرف للعلمية والعجمة`}</p>
    <br />
    <p>{`Let's take one more example before winding it up of`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`جاء موسى`}</strong>{`  `}</p>
    <p>{`موسى
: فاعل مرفوع وعلامة رفعه الضمة المقدرة منع من ظهورها التعذر`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`رأيت موسى`}</strong>{`  `}</p>
    <p>{`موسى
: مفعول به منصوب  وعلامة نصبه الفتحة المقدرة منع من ظهورها التعذر`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`ذهبت إلى موسى`}</strong>{`  `}</p>
    <p>{`موسى
: اسم مجرور وعلامة جره (الفتحة) المقدرة نيابة عن الكسرة لأنه ممنوع من الصرف`}</p>
    <br />
    <p>{`Names of Angels are Diptote Or Mamnu Min As Sarf Except,`}</p>
    <ul>
      <li parentName="ul">{`مالك`}</li>
      <li parentName="ul">{`منكر`}</li>
      <li parentName="ul">{`نكير`}</li>
    </ul>
    <br />
    <p>{`But there are times when Diptote Or Mamnu Min As Sarf can become مصروف again, it happens because of following two reasons,`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Reason 1`}</strong>{` When ال is added to them. For example ذهبت إلى `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`المساجدِ`}</strong>{` الجميلة`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Reason 2`}</strong>{` when it is مضافاً. For example ذهبت إلى `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`مساجدِ`}</strong>{` المدينة`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception 1`}</strong>{` شيطانٌ is not Diptote Or Mamnu Min As Sarf`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception 2`}</strong>{` Some city names even though they are العلمية + مؤنث are منصرف because `}<strong parentName="p">{`Arabs Said So (period)`}</strong>{` i.e. `}<em parentName="p">{`سُمِع من العَرْبِ`}</em>{` OR `}<em parentName="p">{`السماع`}</em>{`. For example`}</p>
    <ul>
      <li parentName="ul">{`حنينٌ In ذهبت إلى `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`حنينٍ`}</strong></li>
      <li parentName="ul">{`بدرٌ In ذهبت إلى `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`بدرٍ`}</strong></li>
      <li parentName="ul">{`مِنَى`}</li>
      <li parentName="ul">{`واسطٌ`}</li>
      <li parentName="ul">{`دابِقٌ`}</li>
      <li parentName="ul">{`حِجرٌ`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception 3`}</strong>{` If العلمية + التأنيث is three lettered and middle letter is sakin, You can treat is as either `}</p>
    <ul>
      <li parentName="ul">{`الممنوع من الصرف OR`}</li>
      <li parentName="ul">{`منصرف`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`العلم المؤنث إذا كان ثلاثياً ساكن الوسط يجوز منعه من الصرف ويجوز صرفه`}</p>
    </blockquote>
    <br />
    <p><em parentName="p">{`Example:`}</em>{` هنْد can is feminine three lettered name with middle letter Sakin. So it possible to say,`}</p>
    <ul>
      <li parentName="ul">{`جاءت `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`هندُ`}</strong>{` OR ذهبت إلى `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`هندَ`}</strong></li>
      <li parentName="ul">{`جاءت `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`هندٌ`}</strong>{` OR ذهبت إلى `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`هندٍ`}</strong></li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception 4`}</strong>{` In poetry, Mamnu min As Sarf can become منصرف. We call it poetic necessity (الضرورة الشعرية)`}</p>
    <blockquote>
      <p parentName="blockquote">{`في الشعر يجوز صرف الممنوع من الصرف`}</p>
    </blockquote>
    <p>{`For example, In ويومَ دخلتُ الخِدْرَ خِدْرَ `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`عُنَيْزَةٍ`}</strong>{` عُنَيْزَةٍ is Feminine name and منصرف`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Heights of Exception`}</strong>{` There are some Arabs who don't have Mamnu Min As Sarf ( Amazing people !!!)`}</p>
    <h2>{`Quranic Examples of Diptote Or Mamnu Min As Sarf`}</h2>
    <ul>
      <li parentName="ul">{`وإذا حييتم بتحية فحيوا `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`بأحسنَ`}</strong>{` منها أو ردوها`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: الوصفية + وزن الفعل`}</li>
        </ul>
      </li>
      <li parentName="ul">{`فرجع `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`موسى`}</strong>{` إلى قومه `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`غضبانَ`}</strong>{` أسفاً`}
        <ul parentName="li">
          <li parentName="ul">{`Reason for غضبانَ is الوصفية + زيادة الألف والنون `}</li>
        </ul>
      </li>
      <li parentName="ul">{`لَقَدْ نَصَرَكُمُ اللَّهُ فِي `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`مَوَاطِنَ`}</strong>{` كَثِيرَةٍ ۙ وَيَوْمَ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`حُنَيْنٍ`}</strong>{` ۙ إِذْ أَعْجَبَتْكُمْ كَثْرَتُكُمْ فَلَمْ تُغْنِ عَنكُمْ شَيْئًا`}
        <ul parentName="li">
          <li parentName="ul">{`Reason for مَوَاطِنَ is : صيغة منتهى الجموع `}</li>
          <li parentName="ul">{`Reason for حُنَيْنٍ is : سُمِع من العَرْبِ (Exception 2 mentioned above)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`يعملون له ما يشاء من `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`محاريبَ`}</strong>{` و`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`تماثيلَ`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Reason: صيغة منتهى الجموع `}</li>
        </ul>
      </li>
      <li parentName="ul">{`إِنَّ أَوَّلَ بَيْتٍ وُضِعَ لِلنَّاسِ لَلَّذِي بِ`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`بَكَّةَ`}</strong>{` مُبَارَكًا وَهُدًى لِّلْعَالَمِينَ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: العلمية + التأنيث`}</li>
        </ul>
      </li>
      <li parentName="ul">{`وَلَقَدْ زَيَّنَّا السَّمَاءَ الدُّنْيَا بِ`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`مَصَابِيحَ`}</strong>{` وَجَعَلْنَاهَا رُجُومًا لِّلشَّيَاطِينِ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: صيغة منتهى الجموع `}</li>
        </ul>
      </li>
      <li parentName="ul">{`وَمُبَشِّرًا بِرَسُولٍ يَأْتِي مِن بَعْدِي اسْمُهُ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أَحْمَدُ`}</strong>{` ۖ فَلَمَّا جَاءَهُم بِالْبَيِّنَاتِ قَالُوا هَٰذَا سِحْرٌ مُّبِينٌ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: الوصفية + وزن الفعل`}</li>
        </ul>
      </li>
      <li parentName="ul">{`لَّقَدْ كَانَ فِي `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`يُوسُفَ`}</strong>{` وَإِخْوَتِهِ آيَاتٌ لِّلسَّائِلِينَ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: العلمية + العُجْمَة`}</li>
        </ul>
      </li>
      <li parentName="ul">{`شَهْرُ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`رَمَضَانَ`}</strong>{` الَّذِي أُنزِلَ فِيهِ الْقُرْآنُ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: العلمية + زيادة الألف والنون`}</li>
        </ul>
      </li>
      <li parentName="ul">{`إِنَّ فِي ذَٰلِكَ لَ`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ذِكْرَىٰ`}</strong>{` لِمَن كَانَ لَهُ قَلْبٌ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: ألف التأنيث المقصورة`}</li>
        </ul>
      </li>
      <li parentName="ul">{`يُطَافُ عَلَيْهِم بِكَأْسٍ مِّن مَّعِينٍ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`بَيْضَاءَ`}</strong>{` لَذَّةٍ لِّلشَّارِبِينَ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: ألف التأنيث الممدودة`}</li>
        </ul>
      </li>
      <li parentName="ul">{`فَمَن كَانَ مِنكُم مَّرِيضًا أَوْ عَلَىٰ سَفَرٍ فَعِدَّةٌ مِّنْ أَيَّامٍ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أُخَرَ`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Reason: الوصفية + العَدْل`}</li>
        </ul>
      </li>
      <li parentName="ul">{`وَعَدَ اللَّهُ الْمُؤْمِنِينَ وَالْمُؤْمِنَاتِ جَنَّاتٍ تَجْرِي مِن تَحْتِهَا الْأَنْهَارُ خَالِدِينَ فِيهَا وَ`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`مَسَاكِنَ`}</strong>{` طَيِّبَةً فِي جَنَّاتِ عَدْنٍ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: صيغة منتهى الجموع`}</li>
        </ul>
      </li>
      <li parentName="ul">{`وَأَنتُمْ عَاكِفُونَ فِي `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`الْمَسَاجِدِ`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Reason: becuase of ال it is منصرف`}</li>
        </ul>
      </li>
      <li parentName="ul">{`وَلَقَدْ نَصَرَكُمُ اللَّهُ بِ`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`بَدْرٍ`}</strong>{` وَأَنتُمْ أَذِلَّةٌ ۖ فَاتَّقُوا اللَّهَ لَعَلَّكُمْ تَشْكُرُونَ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason سُمِع من العَرْبِ (Exception 2 mentioned above)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`لَقَدْ خَلَقْنَا الْإِنسَانَ فِي `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أَحْسَنِ`}</strong>{` تَقْوِيمٍ`}
        <ul parentName="li">
          <li parentName="ul">{`Reason: because it is Mudhaf`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`That's all folks !!!  `}</p>
    <br />
    <p>{`A brief commentary on Diptote Or Mamnu Min As Sarf.`}</p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      